import { extendTheme, withDefaultColorScheme } from '@chakra-ui/react'
const theme = {
  colors: {
    primary: '#4532CC',
    brand: {
      100: '#DDD6FC',
      200: '#BAAEF9',
      300: '#9383EF',
      400: '#7261E0',
      500: '#4532CC',
      600: '#3324AF',
      700: '#251992',
    },
  },
}

export default extendTheme(
  theme,
  withDefaultColorScheme({ colorScheme: 'brand' })
)
