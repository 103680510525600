// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-home-benefits-tsx": () => import("./../../../src/pages/home/Benefits.tsx" /* webpackChunkName: "component---src-pages-home-benefits-tsx" */),
  "component---src-pages-home-blog-page-tsx": () => import("./../../../src/pages/home/BlogPage.tsx" /* webpackChunkName: "component---src-pages-home-blog-page-tsx" */),
  "component---src-pages-home-features-feature-tsx": () => import("./../../../src/pages/home/Features/Feature.tsx" /* webpackChunkName: "component---src-pages-home-features-feature-tsx" */),
  "component---src-pages-home-features-tsx": () => import("./../../../src/pages/home/Features.tsx" /* webpackChunkName: "component---src-pages-home-features-tsx" */),
  "component---src-pages-home-intro-tsx": () => import("./../../../src/pages/home/Intro.tsx" /* webpackChunkName: "component---src-pages-home-intro-tsx" */),
  "component---src-pages-home-partner-tsx": () => import("./../../../src/pages/home/Partner.tsx" /* webpackChunkName: "component---src-pages-home-partner-tsx" */),
  "component---src-pages-home-pricing-pricing-card-tsx": () => import("./../../../src/pages/home/Pricing/PricingCard.tsx" /* webpackChunkName: "component---src-pages-home-pricing-pricing-card-tsx" */),
  "component---src-pages-home-pricing-tsx": () => import("./../../../src/pages/home/Pricing.tsx" /* webpackChunkName: "component---src-pages-home-pricing-tsx" */),
  "component---src-pages-home-ready-invitation-form-tsx": () => import("./../../../src/pages/home/Ready/InvitationForm.tsx" /* webpackChunkName: "component---src-pages-home-ready-invitation-form-tsx" */),
  "component---src-pages-home-ready-tsx": () => import("./../../../src/pages/home/Ready.tsx" /* webpackChunkName: "component---src-pages-home-ready-tsx" */),
  "component---src-pages-home-testimonial-cta-button-tsx": () => import("./../../../src/pages/home/Testimonial/CTAButton.tsx" /* webpackChunkName: "component---src-pages-home-testimonial-cta-button-tsx" */),
  "component---src-pages-home-testimonial-feature-tsx": () => import("./../../../src/pages/home/Testimonial/Feature.tsx" /* webpackChunkName: "component---src-pages-home-testimonial-feature-tsx" */),
  "component---src-pages-home-use-case-tsx": () => import("./../../../src/pages/home/UseCase.tsx" /* webpackChunkName: "component---src-pages-home-use-case-tsx" */),
  "component---src-pages-home-utils-underline-link-tsx": () => import("./../../../src/pages/home/utils/UnderlineLink.tsx" /* webpackChunkName: "component---src-pages-home-utils-underline-link-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-our-story-customer-tsx": () => import("./../../../src/pages/ourStory/Customer.tsx" /* webpackChunkName: "component---src-pages-our-story-customer-tsx" */),
  "component---src-pages-our-story-feature-tsx": () => import("./../../../src/pages/ourStory/Feature.tsx" /* webpackChunkName: "component---src-pages-our-story-feature-tsx" */),
  "component---src-pages-our-story-partner-tsx": () => import("./../../../src/pages/ourStory/Partner.tsx" /* webpackChunkName: "component---src-pages-our-story-partner-tsx" */),
  "component---src-pages-our-story-security-tsx": () => import("./../../../src/pages/ourStory/Security.tsx" /* webpackChunkName: "component---src-pages-our-story-security-tsx" */),
  "component---src-pages-our-story-stat-tsx": () => import("./../../../src/pages/ourStory/Stat.tsx" /* webpackChunkName: "component---src-pages-our-story-stat-tsx" */),
  "component---src-pages-our-story-tsx": () => import("./../../../src/pages/ourStory.tsx" /* webpackChunkName: "component---src-pages-our-story-tsx" */),
  "component---src-pages-our-story-who-we-are-tsx": () => import("./../../../src/pages/ourStory/WhoWeAre.tsx" /* webpackChunkName: "component---src-pages-our-story-who-we-are-tsx" */),
  "component---src-pages-policy-tsx": () => import("./../../../src/pages/policy.tsx" /* webpackChunkName: "component---src-pages-policy-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-terms-of-use-tsx": () => import("./../../../src/pages/termsOfUse.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */)
}

